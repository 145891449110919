import './index.scss'
import pipeImg from '@/assets/images/h5/pipe.png'
import rulesImg from '@/assets/images/h5/rules.png'
import longImg from '@/assets/images/h5/long_pipe.png'
import gameImg from '@/assets/images/h5/gametoearn@2x.png'
import { useNavigate } from 'react-router-dom';

function H5RulesAndGame() {
  const navigate = useNavigate()

  const push = (url) => {
    navigate(url);
  }
  return(
    <div className='h5RulesAndGame'>
      <div className='dots'>
        <div></div>
        <div></div>
      </div>
      <div className='rulesAndGameBtns'>
        <div className='rulesBtns'>
          <img src={pipeImg} className='pipe' alt="" />
          {/* push('/rules'); */}
          <img onClick={() => {push('/rules')}} src={rulesImg} className='rules' alt="" />
        </div>
        <div className='GameBtns'>
          <img src={gameImg} onClick={() => {push('/games')}} className='rules' alt="" />
          <img src={pipeImg} className='pipe' alt="" />
        </div>
      </div>
      <img src={longImg} className='long' alt="" />
    </div>
  )
}

export default H5RulesAndGame