import { Mask } from "antd-mobile";
import closeSvg from '@assets/images/h5/close.svg';
import kefu2 from  '@assets/images/h5/kefu2.svg';
import kefu3 from  '@assets/images/h5/kefu3.svg';
import kefu4 from  '@assets/images/h5/kefu4.svg';
import kefu5 from  '@assets/images/h5/kefu5.svg';

import './index.scss'
import { ReactNode } from "react";

export default function Index({ visible, onClose, text='Order payment exception, please contact customer service for handling.', type=0 }: { visible: boolean, onClose?: Function, text?: string | ReactNode, type:0 | 1 }) {
    return <Mask visible={visible} onMaskClick={() => {
        onClose && onClose();
    }}>
        <div className="maskContainer">
            <img className="closeIcon" src={closeSvg} onClick={()=>{
                  onClose && onClose();
            }}/>
            <div className="title">
                Tips
            </div>
            <div className="maskContent">{text}</div>

            <div className="btnPanel">
                <div className="imgbtns"> 
                    {
                        type===0?
                        <>
                            <img src={kefu5} alt="" />
                            <img src={kefu2} alt="" />
                            <img src={kefu3} alt="" />
                            <img src={kefu4} alt="" />
                        </>:
                        <img src={kefu4} alt="" />
                    }
                     
                </div>
            </div>
        </div>
    </Mask>
}