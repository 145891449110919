import { Mask } from "antd-mobile";
import closeSvg from '@assets/images/h5/close.svg'

import './index.scss'
import { ReactNode } from "react";

export default function Index({ visible, onClose, text, btnText,onBtnClick }: { visible: boolean, onClose?: Function, text: string | ReactNode, btnText: string,onBtnClick?:Function }) {
    return <Mask visible={visible} onMaskClick={() => {
        onClose && onClose();
    }}>
        <div className="maskContainer">
            <img className="closeIcon" src={closeSvg} onClick={()=>{
                  onClose && onClose();
            }}/>
            <div className="title">
                Tips
            </div>
            <div className="maskContent">{text}</div>

            <div className="btnPanel">
                <div className="btns" onClick={()=>{
                    onBtnClick && onBtnClick();
                    onClose && onClose();
                }}> 
                    {btnText}
                </div>
            </div>
        </div>
    </Mask>
}