import { Mask, Toast } from "antd-mobile";
import closeSvg from '@assets/images/h5/close.svg';
import walletSvg from '@assets/images/h5/wallet.svg';
import zhuyiSvg from '@assets/images/h5/zhuyi.svg';

import './index.scss'
import { getAddress, stringCopy } from "@/utils/stringFormat";

export default function Index({ visible, onClose, address }: { visible: boolean, onClose?: Function, address: string, }) {
    return <Mask visible={visible} onMaskClick={() => {
        onClose && onClose();
    }}>
        <div className="maskContainer funsContainer">
            <img className="closeIcon" src={closeSvg} onClick={() => {
                onClose && onClose();
            }} />
            <div className="title">
                Tips
            </div>
            <div className="maskContent">
                <img src={walletSvg} />
                <div className="addFunsContentText">
                    Transfer funds from an <label>exchange <img src={zhuyiSvg} alt="" /></label>       or another wallet to your wallet address below:
                </div>
            </div>

            <div className="addressPanel">
                {getAddress(address)}
            </div>

            <div className="btnPanel">
                <div className="btns" onClick={() => {
                    stringCopy(address, (e: boolean) => {
                        if (e) {
                            Toast.show("Copied")
                        } else {
                            Toast.show("copy failed")
                        }
                    })
                }}>
                    Copy
                </div>
            </div>
        </div>
    </Mask>
}