import './h5Loading.scss'
import textImg from '@/assets/images/home/text.gif'
import { useEffect, useState } from "react"
import portrait from '@/assets/images/h5/portrait.svg'
import portrait1 from '@/assets/images/h5/portrait1.svg'
import gametaLogo from '@/assets/images/h5/gameta-logo.svg'
import eleLogo from '@/assets/images/h5/ele-logo.svg'


function H5Loading() {
  const [lightStat, setLightStat] = useState(false)
  const [active, setActive] = useState(false)
  useEffect(()=> {
    const timer = setTimeout(() => {
      setLightStat(!lightStat)
      setActive(!active)
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [lightStat])
  useEffect(()=> {
    const timer = setTimeout(() => {
      setActive(!active)
    }, 200)
    return () => {
      clearTimeout(timer)
    }
  }, [active])
  return(
    <div className='h5Loading'>
      <img src={textImg} className='topGif' alt="" />
      <div className='card'>
        <div className={lightStat ? 'deng1' : "deng2"} />
        <div className='fire'></div>
        <div className='inner'>
          <div className={active === false? 'active': 'uncommon'}>
            <div className='hm'>
              <img src={portrait} alt="" />
              <div className='price'><span>0.1&nbsp;</span>BNB</div>
              <div className='bottom'><img src={eleLogo} alt="" /><span>&nbsp;Floor Price</span></div>
            </div>
          </div>
          <div className={active === true? 'active': 'uncommon'}>
            <div className='mh'>
              <img src={portrait1} alt="" />
              <div className='price'><span>20&nbsp;</span>USDT</div>
              <div className='bottom'><img src={gametaLogo} alt="" /><span>&nbsp;Gaming Value</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default H5Loading