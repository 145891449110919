import './index.scss'
import OpenSea_Full_Logo from '@assets/images/home/element_logo.png';
import { useNavigate } from 'react-router-dom';
import { config } from '@/config';

export default function Index({ disabled, gmbList }: { disabled: boolean, gmbList: any }) {

    const navigate = useNavigate()
    
    return <div className="h5CongratulationsFooter">
        {/* <div className="text">You have checked  <label>5</label> NFTs, and the total price : <label>20</label> BNB.</div> */}
        <div className='btns'>
            {
                gmbList && gmbList.length === 1 && gmbList[0].manghe === 0 &&
                <div className={`btn1 ${disabled && 'disabled'}`} onClick={() => {
                    if (!disabled) {
                        window.open(config.elementAccountUrl)
                    }
                }} >
                    <img src={OpenSea_Full_Logo} /> LIST ON ELEMENT
                </div>
            }
            {
                gmbList && gmbList.length === 0 &&
                <div className={`btn1 ${disabled && 'disabled'}`} onClick={() => {
                    if (!disabled) {
                        window.open(config.elementAccountUrl)
                    }
                }} >
                    <img src={OpenSea_Full_Logo} /> LIST ON ELEMENT
                </div>
            }
            <div className='btn2' onClick={() => {
                window.open(config.sellUrl)
            }}>
                How to sell
            </div>
        </div>
    </div>
}