import { Popup } from "antd-mobile" 
export default function Index({ visible, children }: {
    visible: boolean,
    children: any
}) {
    return <Popup

        visible={visible}
        // onMaskClick={() => {
        //     setVisible(false)
        // }}
        bodyStyle={{ height: 'calc(100vh - 50px)',background:"#120E1D" }}
    >
        {children}
    </Popup>
}