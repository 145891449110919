import { useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import Body, { H5Body } from '@components/h5Body'
import headImg from '@assets/images/h5/headerImg.svg'
import logoImg from '@assets/images/h5/logo-top.svg'
import ellipseImg from '@assets/images/h5/ellipse.svg'
import right from '@assets/images/h5/right.svg';
import { useConnectKit, useAccount } from '@particle-network/connect-react-ui'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { getAddress } from '@/utils/stringFormat'
import AddFunsTipsModal from '@components/h5Tips/addFunsTips'

export default function Balance() {
  const navigate = useNavigate();
  const account = useAccount();
  const [balance, setBalance] = useState("0");
  const connectkit = useConnectKit();
  const [visible, setVisible] = useState(false);

  const { rate } = useSelector((state: RootState) => {
    return {
      rate: state.globalModule.price
    }
  })
  useEffect(() => {
    if (account && rate) {
      getBalance();
    }
  }, [account, rate])

  const getBalance = async () => {
    const balance = await window.web3.eth.getBalance(account || '');
    console.log(window.web3.utils.fromWei(balance, "ether"))

    const totalPrice: any = Number(window.web3.utils.fromWei(balance, "ether")) * Number(rate)
    setBalance(totalPrice.toFixed(2));
  };




  return (
    <>
      <Body type={H5Body.type2} className="balanceContainer">
        <div className='topCard'>
          <div className='topPanel'>
            <img src={right} className="right" onClick={() => { navigate(-1) }} alt="" />
          </div>
        </div>
        <img src={headImg} className="headImg" alt="" />
        <img src={logoImg} className="logo" alt="" />
        <div className='wallet'>{getAddress(account || '')}</div>
        <div className='moneyBox'>
          <div className='money'>$ {balance}</div>
          <div className='dw'>Total Balance(USD)</div>
        </div>
        <div className='addBtn' onClick={() => {
          setVisible(true)
        }}>Add Funds</div>
        <div className='refBtn' onClick={()=>{
          getBalance();
        }}>Refresh Funds</div>
        <div className='out' onClick={() => {
          connectkit.disconnect();
          navigate("/")
        }}>
          <img src={ellipseImg} className="ell" alt="" />
          <span>Log Out</span>
        </div>
      </Body>
      <AddFunsTipsModal visible={visible} address={account || ''} onClose={() => {
        setVisible(false)
      }} />
    </>
  )
}
