import './index.scss';
// import SwiperCore, { Autoplay } from 'swiper/core' 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import deng1 from '@assets/images/h5/deng3.svg';
import deng4 from '@assets/images/h5/deng4.svg';
import H5ChristmasDeng from '../h5ChristmasDeng'; 

import dash_kuang0 from '@assets/images/h5/dash-kuang0.svg';
import element_logo from '@assets/images/home/element_logo.png';
import tapd from '@assets/images/home/tapd.png';
import { RightOutlined, SyncOutlined } from '@ant-design/icons';

import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useNavigate } from 'react-router-dom'
import { getInfo_func, issuance_Func } from '@/features/globalClice';
import { getImage,getBgImage } from '@/utils/stringFormat';

const dataList = [

  { img: 'card1', cardImg: dash_kuang0, priceInGame: '0.4', logo: element_logo, recyclingPrice: 'Floor Price' },
  { img: 'card2', cardImg: dash_kuang0, priceInGame: '1.5', logo: tapd, recyclingPrice: 'Floor Price' }
]

export default function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [topMint, setTopMint] = useState<any[]>([]);
  const [roate, setRoate] = useState(false)

  const [cardList, setCardList] = useState(dataList)

  const { issuanceObj, tradIndexStat, hyperMint, urls }: { issuanceObj: any, tradIndexStat: any, hyperMint: any, urls: string } = useSelector((state: RootState) => {
    return {
      issuanceObj: state.globalModule.issuance,
      tradIndexStat: state.globalModule.tradIndexStat,
      hyperMint: state.globalModule.hyperMintList,
      urls: state.globalModule.urls
    }
  })

  useEffect(() => {
    setHyperMint()
  }, [hyperMint])


  useLayoutEffect(() => {
    if (issuanceObj?.id) {
      dispatch(getInfo_func(issuanceObj.id, (list: any) => {

        if (list.length === 2) {
          const array = list.map((item: any, index: number) => {
            return {
              ...cardList[index],
              img: getImage(urls, item.image),
              priceInGame: Number(item.recyclingPrice) > 0 ? item.recyclingPrice : item.priceInGame,
              recyclingPrice: Number(item.recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
              unit: Number(item.recyclingPrice) > 0 ? 'BNB' : 'USDT',
              cardImg:getBgImage('h5', item.level,2),
              name:item.name,
              logo: Number(item.recyclingPrice) > 0 ? element_logo : tapd
            }
          })


          setCardList(array)
        }
      }))
    }
  }, [issuanceObj?.id, urls])


  function setHyperMint() {
    const arr = []
    const hyperMintList: any[] = [];
    const mintList = hyperMint
    mintList.forEach((item: any, index: number) => {
      if (index < 10) {
        hyperMintList.push(item)
      }
    })
    for (let k = 0; k < hyperMintList.length; k++) {
      const obj: any = {}
      obj.priceInGame = hyperMintList[k].priceInGame
      obj.recyclingPrice = hyperMintList[k].recyclingPrice
      let image = hyperMintList[k].images
      const newImage = image.split(",")
      obj.images1 = newImage[0] ? newImage[0] : ''
      obj.images2 = newImage[1] ? newImage[1] : ''
      obj.newBaby = hyperMintList[k].wallet.substring(0, 2) + '...' + hyperMintList[k].wallet.substring(hyperMintList[k].wallet.length - 2, hyperMintList[k].wallet.length)
      arr.push(obj)
    }
    setTopMint(arr)
  }



  function refreshData() {
    setRoate(true)
    setTimeout(() => {
      setRoate(false)
    }, 1500)
    dispatch(issuance_Func())
  }
  return <div className="cardcontentContainer">
    <div className='topRoll'>
      {
        topMint.length !== 0 &&
        <Swiper
          className="h5Swiper-container swiper-no-swiping"
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 1000, // 默认延迟3s播放下一张
            stopOnLastSlide: false, // 播放到最后一张时停止：否
            disableOnInteraction: false // 用户操作轮播图后停止 ：是
          }}
          direction='vertical'
          loop
        >
          {
            topMint.map((item, index) =>
              <SwiperSlide className="h5Swiper-slide" key={index}>
                <div className="baby">
                  <div className="beele">{item.newBaby}</div>
                </div>
                <span className="successful">Won<span className="sol">{' ' + item.recyclingPrice + ' '}</span> BNB of Lucky Mint</span>
                <div className="prize">
                  {
                    item.images1 && <img src={item.images1} alt="" />
                  }
                  {
                    item.images2 && <img src={item.images2} alt="" />
                  }
                  {
                    item.images2 && <div className="ellipsis">...</div>
                  }
                </div>
              </SwiperSlide>
            )
          }
        </Swiper>
      }
    </div>
    <div className='card-body'>
      <H5ChristmasDeng img1={deng1} img2={deng4} />
      <div className="content">
        <div className='cards'>
          {
            cardList.map((item: any,index:number) => {
              return <div key={`${item.name}${index}`} className={`card`} style={{backgroundImage:`url(${item.cardImg})`}}>
                <img src={item.img} className="cardImg" />
                <div className="blance">
                  <label>{item.priceInGame}</label> {item.unit}
                </div>
                <div className='text'>
                  <img src={item.logo} className="logo" /> {item.recyclingPrice}
                </div>
              </div>
            })
          }
     
        </div>
        <div className="btm">
          <div>
            {issuanceObj ? (issuanceObj.total - issuanceObj.sold) : '0'}/{issuanceObj ? issuanceObj.total : '0'}  <SyncOutlined onClick={refreshData} className='refresh' spin={roate} />
          </div>
          <div onClick={() => {
            navigate('/h5Rules')
          }}>
            Rules<RightOutlined />
          </div>
        </div>
      </div>
      <div className='card-footer'>
        lucky MINT： <label>{issuanceObj ? issuanceObj.price : '0'} </label> BNB / mint
      </div>
    </div>
  </div>
}