import './index.scss'
import mintImg from '@/assets/images/home/mint_btn.png'
import zsImg from '@/assets/images/h5/zhuangshi.png'
import load_white_gif from '@/assets/images/home/load_white.gif'
import load_kp from '@/assets/images/home/kp.gif'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { create_order_func } from '@features/orderClice';
import { cancel_order_func, ReasonEnum, payDone_func } from '@features/orderClice';
import { useAccount, useConnectModal } from "@particle-network/connect-react-ui";
import H5Tips from '@/components/h5Tips'
import KfTips from '@/components/h5Tips/kefuTips'
// import WhiteTips from '@/components/h5Tips/whitelist'
import WhiteTips from '@/components/h5Tips/whiteTips'
import H5Loading from '@/components/h5Tips/h5Loading'
import { getWhiteList_Func } from '@/features/globalClice'

function StatrMint() {
  const dispatch = useDispatch()
  const navigator = useNavigate()
  const account = useAccount();
  const connectKit = useConnectModal();
  const [whiteGif, setWhiteGif] = useState(false)
  const [loadGif, setLoadGif] = useState(false)
  const [test, setTest] = useState('')
  const [btnText, setBtnTest] = useState('')
  const [kfVisible, setkfVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [whiteVisible, setWhiteVisible] = useState(false)
  const [title, setTitle] = useState('')
  const [remarkList, setRemarkList] = useState([])
  const [whiteData, setWhiteData] = useState({})
  const [type, setType] = useState(0)
  const { quantity, issuanceObj, orderInfo, itemInfo } = useSelector((state) => {
    return {
      quantity: state.globalModule.quantity,
      issuanceObj: state.globalModule.issuance,
      orderInfo:state.orderModule.orderInfo,
      itemInfo: state.globalModule.itemInfo
    }
  })
  function onBtnClick() {
    navigator('/mint')
  }

  function closeWhite(stat) {
    if (stat === 0 || stat === undefined) {
      dispatch(cancel_order_func(ReasonEnum.cancel, () => {
        setWhiteVisible(false);
      }))
    } else if(stat === 1) {
      setWhiteVisible(false)
      window.web3.eth.getBalance(account).then(value => {
        const balance = window.web3.utils.fromWei(value, "ether");
        if (Number(orderInfo.totalPrice) > Number(balance)) {
          // dispatch(cancel_order_func(ReasonEnum.cancel, () => {
          //   setShowBuyBackDialog(false);
          // }))
          setWhiteGif(false)
          navigator('/addPurchase')
        } else {
          setWhiteGif(true)
          dispatch(payDone_func((status) => {
            setWhiteGif(false)
            if (status == 0) {
              setLoadGif(true); 
            }
            if (status == 1) {
              setLoadGif(false);
              dispatch(getWhiteList_Func())
              navigator("/congratulations", {
                state: {
                  orderId: whiteData.order.id
                }
              })
            } else if (status == 2) {
              //failed
            } else if (status == 3) {
              //payFailed
              // setShowGameDialog(true)
            } else if (status == 4) {
              // paidFailed
            } else if (status === 5) {
              // 无效订单
              setkfVisible(true)
              setTest('This payment is abnormal,unable to mint.')
              setBtnTest('')
            }
          }, () => {
            setLoadGif(false);
          }));
        }
      });
      // 此处需先判断余额是否充足若充足则出现loading动画 否则出现余额不足弹窗 
      // setLoadStat(true)
      // openResultDialog()
    }
  }

  function mint() {
    if (account) {
      dispatch(create_order_func(quantity, (data, status) => {
        if (status) {
          // 先判断是否是白名单补偿
          if (data.order.usedWhitelist) {
            if (data.order.actualPrice === '0') {
              setTitle('Free')
              const remark = [
                'No charge for MINT', 
                `<span>Scope of application: ${itemInfo.type}</span>`, 
                `<span>MINT option: ${data.order.quantity} MINT</span>`, 
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            } else if(Number(data.order.actualPrice) > 0 && data.order.guaranteed === true) {
              setTitle('Breakeven')
              const remark = [
                `If the repurchase amount is less than the payment amount, the official will compensate for the loss`,
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === false) {
              setTitle('Discount')
              const remark = [
                `Use discount price to participate in MINT`,
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            }
            setWhiteVisible(true)
            setWhiteData(data)
          } else {
            setWhiteGif(true)
            window.web3.eth.getBalance(account).then(value => {
              const balance = window.web3.utils.fromWei(value, "ether");
              if (Number(data.order.totalPrice) > Number(balance)) {
                setWhiteGif(false)
                navigator('/addPurchase')
              } else {
                // 不是直接调用支付（此时用户为普通用户）
                dispatch(payDone_func((status) => {
                  setWhiteGif(false)
                  if (status == 0) {
                    setLoadGif(true); 
                  }
                  if (status == 1) {
                    setLoadGif(false);
                    dispatch(getWhiteList_Func())
                    navigator("/congratulations", {
                      state: {
                        orderId: data.order.id
                      }
                    })
                  } else if (status == 2) {
                    //failed
                    // setShowLuckDialog(true)
                  } else if (status == 3) {
                    //payFailed
                    // setShowGameDialog(true)
                  } else if (status == 4) {
                    // paidFailed
                  } else if (status === 5) {
                    // 无效订单
                    setkfVisible(true)
                    setTest('This payment is abnormal,unable to mint.')
                    setBtnTest('')
                  }
                }, () => {
                  setLoadGif(false);
                }));
              }
            });
          }
          console.log(data.order);
        } else {
          if (data === 'SI060') {
            // 尚未发售订单
            setVisible(true)
            setTest('The time for lucky mint has not yet arrived,\n please wait patiently.')
            setBtnTest('Confirm')
          }
          if (data === 'SI052') {
            setVisible(true)
            setTest('There is an order has not been completed,\n please complete the payment first.')
            setBtnTest('view')
          }
          if (data === 'SI051') {
            setVisible(true)
            setTest('Insufficient inventory,\n please reduce the number of lucky mint.')
            setBtnTest('Confirm')
          }
        }
      }))
    } else {
      connectKit.openConnectModal()
    }
    // navigator('/h5/addPurchase')
    // console.log(quantity);
    // setLoadGif(true)
  }


  return (
    <div className='statrMint'>
      <div className='mintTop'>
        <div>{issuanceObj?.price}&nbsp;<span>BNB&nbsp;/&nbsp;MINT</span></div>
        <img src={mintImg} className='entr' alt="" onClick={(e) => {
          navigator('/mint')
          e.stopPropagation()
        }} />
      </div>
      <div className='zhuangshi'>
        <img src={zsImg} alt="" />
      </div>
      <div className='mintBtn' onClick={mint}></div>
      {
        whiteGif &&
        <div className='white'>
          <img src={load_white_gif} className="loadWhite" alt="" />
          <div className='propgr'>Payment is in progress. <br />Please do not close or refresh the webpage.</div>
        </div>
      }
      {
        loadGif &&
        <div className='white2'>
          {/* <H5Loading /> */}
          <img src={load_kp} className="kpLoad" alt="" />
        </div>
      }
      <H5Tips visible={visible} onClose={() => { setVisible(false) }} text={test} btnText={btnText} onBtnClick={onBtnClick} />
      <KfTips visible={kfVisible} onClose={() => { setkfVisible(false) }} text={test} type={1} />
      <WhiteTips visible={whiteVisible} onClose={(stat) => { closeWhite(stat) }} title={title} remarkList={remarkList} />
    </div>
  )
}

export default StatrMint