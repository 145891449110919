
import './index.scss'
import Footer from '@components/h5Footer'
import Content from '@/components/h51of2CardContent'
import StartMint from '@/components/h51of2StatrMint'
import Body, { H5Body } from '@components/h5Body'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import RulesAndGame from '@/components/h5RulesAndGame'
import { useDispatch, useSelector } from 'react-redux'
import { getInfo_func, getWhiteList_Func, issuance_Func, getHyperMint_Func } from '@/features/globalClice'
import React, { useState, useEffect } from "react";
import { useAccount } from "@particle-network/connect-react-ui";
import log2Img from '../../../assets/images/home/log2.png'
import discordImg from '../../../assets/images/home/icon-Discord.png';
import { config } from '@/config'
import HisTroy from '@/components/h51of2HisToryPanel'

export default function Index() {
    const dispatch = useDispatch()
    const account = useAccount()
    const navigate = useNavigate()
    const { issuanceObj, itemInfo, token }: any = useSelector((state: any) => {
        return {
            issuanceObj: state.globalModule.issuance,
            itemInfo: state.globalModule.itemInfo,
            token: state.loginModule.token
        }
    })


    useEffect(() => {
        dispatch(issuance_Func())
        dispatch(getHyperMint_Func());
        if (account) {
            dispatch(getWhiteList_Func())
        }
    }, [itemInfo?.phase, token])

    function goDiscord() {
        window.open(config.discordUrl)
    }

    function goTwitter() {
        window.open(config.twitterUrl)
    }

    function back() {
        navigate('/')
    }

    const state = useLocation().state;
    if (state == undefined) {
        return <Navigate replace to="/" />
    }
    return <>
        <Body>
            <div className="elementPanel">
                <div className='hyper'>{issuanceObj?.title}</div>
                <div className='studio'>{issuanceObj?.studio}</div>
            </div>


            <Content />
            <StartMint />
            <HisTroy />
            {/* <RulesAndGame /> */}

        </Body>
        <Footer />
        {
            issuanceObj?.status === 4 &&
            <div className='mask'>
                <div className='maskContent'>
                    <div className='talk'>This issue of Lucky Mint has ended. <br />If you want to continue participating, <br />please join the community.</div>
                    <div className='btn'>
                        <div className='one'><img className="img4" onClick={goDiscord} src={discordImg} alt="" /></div>
                        <div><img className="img3" onClick={goTwitter} src={log2Img} alt="" /></div>
                    </div>
                    <div className='back' onClick={back}>Back</div>
                </div>
            </div>
        }
    </>
}