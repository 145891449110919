 
import { useNavigate } from 'react-router-dom'
import Body, { H5Body } from '@components/h5Body'
import NavBar from '@components/H5NavBar'
import Table from '@components/H5Table'
import './index.scss'



export default function Index() {
    const navigate = useNavigate();
    return <>
        <NavBar onBack={() => {
            navigate(-1)
        }}> My Lucky MINT</NavBar>
        <Body type={H5Body.type2}> 
            <Table />
        </Body>
    </>
}