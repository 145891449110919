
import { useEffect, useState } from 'react';
export default function Index({ img1, img2 }: {
    img1: any,
    img2: any
}) {
    const [deng, setDeng] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            setDeng(!deng)
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [deng])
    return <img src={deng ? img1 : img2} className="deng" />
}