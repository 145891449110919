import './index.scss';
import shoubing from '@assets/images/h5/shoubing.svg';
import btn5 from '@assets/images/h5/btn5.svg'
import btn1 from '@assets/images/h5/btn1.svg'
import load_white_gif from '@/assets/images/home/load_white.gif'
import load_kp from '@/assets/images/home/kp.gif'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { mintCount_func } from '@/features/activitiesClice';
import { useAccount, useConnectModal } from "@particle-network/connect-react-ui";
import { create_order_func, cancel_order_func, ReasonEnum, payDone_func } from '@features/orderClice';
import { getWhiteList_Func, issuance_Func, getHyperMint_Func } from '@/features/globalClice'
import { useDispatch, useSelector } from 'react-redux'

import Popup from '@/components/h5Tips/popup';
import Congratulations from '../../views/h5View/congratulations2'
import H5Tips from '@/components/h5Tips'
import KfTips from '@/components/h5Tips/kefuTips'
import WhiteTips from '@/components/h5Tips/whiteTips'

export default function Index(){
    const account: any = useAccount()
    const dispatch = useDispatch()
    const connectKit: any = useConnectModal();
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [remarkList, setRemarkList] = useState([])
    const [whiteData, setWhiteData] = useState({ order: { id: 0 } })
    const [whiteVisible, setWhiteVisible] = useState(false)
    const [whiteGif, setWhiteGif] = useState(false)
    const [loadGif, setLoadGif] = useState(false)
    const [drawerStat, setDrawerStat] = useState(false);
    const [orderId, setOrderId] = useState(0);
    const [test, setTest] = useState('')
    const [btnText, setBtnTest] = useState('')
    const [kfVisible, setkfVisible] = useState(false)
    const [visible, setVisible] = useState(false);
    const [quantityNumber, setQuantityNumber] = useState('1')

    const { issuanceObj, itemInfo, orderInfo, token, hyperMint }: any = useSelector((state: any) => {
        return {
            orderInfo: state.orderModule.orderInfo,
            issuanceObj: state.globalModule.issuance,
            itemInfo: state.globalModule.itemInfo,
            token: state.loginModule.token,
            hyperMint: state.globalModule.hyperMintList
        }
    })

    function mint(quantityNumber:any) {
        if (account) {
            dispatch(create_order_func(quantityNumber , (data:any, status:any) => {
                if (status) {
                    setQuantityNumber(quantityNumber)
                    if (data.order.usedWhitelist) {
                        if (data.order.actualPrice === '0') {
                            setTitle('Free')
                            const remark: any = [
                                'No charge for MINT',
                                `<span>Scope of application: ${itemInfo.type}</span>`,
                                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
                            ]
                            setRemarkList(remark)
                        } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === true) {
                            setTitle('Breakeven')
                            const remark: any = [
                                `If the repurchase amount is less than the payment amount, the official will compensate for the loss`,
                                `<span>Scope of application: ${itemInfo.type}</span>`,
                                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
                            ]
                            setRemarkList(remark)
                        } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === false) {
                            setTitle('Discount')
                            const remark: any = [
                                `Use discount price to participate in MINT`,
                                `<span>Scope of application: ${itemInfo.type}</span>`,
                                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
                            ]
                            setRemarkList(remark)
                        }
                        setWhiteVisible(true)
                        setWhiteData(data)
                    } else {
                        setWhiteGif(true)
                        window.web3.eth.getBalance(account).then(value => {
                            const balance = window.web3.utils.fromWei(value, "ether");
                            if (Number(data.order.totalPrice) > Number(balance)) {
                                setWhiteGif(false)
                                navigate('/addPurchase')
                            } else {
                                // 不是直接调用支付（此时用户为普通用户）
                                dispatch(payDone_func((status) => {
                                    setWhiteGif(false)
                                    if (status == 0) {
                                        setLoadGif(true);
                                    }
                                    if (status == 1) {
                                        setLoadGif(false);
                                        dispatch(getWhiteList_Func())
                                        setOrderId(data.order.id)
                                        if (quantityNumber === '1') {
                                            setDrawerStat(true)
                                        } else {
                                            navigate("/congratulations", {
                                                state: {
                                                  orderId: whiteData.order.id
                                                }
                                            })
                                        }
                                    } else if (status == 2) {
                                        //failed
                                        // setShowLuckDialog(true)
                                    } else if (status == 3) {
                                        //payFailed
                                        // setShowGameDialog(true)
                                    } else if (status == 4) {
                                        // paidFailed
                                    } else if (status === 5) {
                                        // 无效订单
                                        setkfVisible(true)
                                        setTest('This payment is abnormal,unable to mint.')
                                        setBtnTest('')
                                    }
                                }, () => {
                                    setLoadGif(false);
                                }));
                            }
                        });
                    }
                } else {
                    if (data === 'SI060') {
                        // 尚未发售订单
                        setVisible(true)
                        setTest('The time for lucky mint has not yet arrived,\n please wait patiently.')
                        setBtnTest('Confirm')
                    }
                    if (data === 'SI052') {
                        setVisible(true)
                        setTest('There is an order has not been completed,\n please complete the payment first.')
                        setBtnTest('view')
                    }
                    if (data === 'SI051') {
                        setVisible(true)
                        setTest('Insufficient inventory,\n please reduce the number of lucky mint.')
                        setBtnTest('Confirm')
                    }
                }
            }))
        } else {
            connectKit.openConnectModal()
        }
    }

    function closeWhite(stat: any) {
        if (stat === 0 || stat === undefined) {
            dispatch(cancel_order_func(ReasonEnum.cancel, () => {
                setWhiteVisible(false);
            }))
        } else if (stat === 1) {
            setWhiteVisible(false)
            setQuantityNumber(quantityNumber)
            window.web3.eth.getBalance(account).then(value => {
                const balance = window.web3.utils.fromWei(value, "ether");
                if (Number(orderInfo.totalPrice) > Number(balance)) {
                    // dispatch(cancel_order_func(ReasonEnum.cancel, () => {
                    //   setShowBuyBackDialog(false);
                    // }))
                    setWhiteGif(false)
                    navigate('/addPurchase')
                } else {
                    setWhiteGif(true)
                    dispatch(payDone_func((status) => {
                        setWhiteGif(false)
                        if (status == 0) {
                            setLoadGif(true);
                        }
                        if (status == 1) {
                            setLoadGif(false);
                            dispatch(getWhiteList_Func())
                            setOrderId(whiteData.order.id)
                            if (quantityNumber === '1') {
                                setDrawerStat(true)
                            } else {
                                navigate("/congratulations", {
                                    state: {
                                      orderId: whiteData.order.id
                                    }
                                })
                            }
                        } else if (status == 2) {
                            //failed
                        } else if (status == 3) {
                            //payFailed
                            // setShowGameDialog(true)
                        } else if (status == 4) {
                            // paidFailed
                        } else if (status === 5) {
                            // 无效订单
                            setkfVisible(true)
                            setTest('This payment is abnormal,unable to mint.')
                            setBtnTest('')
                        }
                    }, () => {
                        setLoadGif(false);
                    }));
                }
            });
        }
    }

    return <div className="statr-mint2-container">
        <img src={shoubing} className='shoubing' />
        <div className='mintBtns'>
            <img src={btn5} onClick={()=> {mint('5')}} />
            <img src={btn1} onClick={()=> {mint('1')}} />
        </div>
        {
            whiteGif &&
            <div className='white'>
                <img src={load_white_gif} className="loadWhite" alt="" />
                <div className='propgr'>Payment is in progress. <br />Please do not close or refresh the webpage.</div>
            </div>
        }
        {
            loadGif &&
            <div className='white2'>
                <img src={load_kp} className="kpLoad" alt="" />
            </div>
        }
        {
            quantityNumber === '1' &&
            <Popup visible={drawerStat}>
                <Congratulations orderId={orderId} onContinue={() => { setDrawerStat(false); mint('1') }} onClose={() => {
                    setDrawerStat(false)
                }} />
            </Popup>
        }
        
        <H5Tips visible={visible} onClose={() => { setVisible(false) }} text={test} btnText={btnText} onBtnClick={() => {navigate('/mint')}} />
        <KfTips visible={kfVisible} onClose={() => { setkfVisible(false) }} text={test} type={1} />
        <WhiteTips visible={whiteVisible} onClose={(stat: any) => { closeWhite(stat) }} title={title} remarkList={remarkList} />
    </div>
}