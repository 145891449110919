
import { useNavigate } from 'react-router-dom'
import Body, { H5Body } from '@components/h5Body'
import NavBar from '@components/H5NavBar'
import {List} from 'antd-mobile';
import ListItem from '@components/h5TopWinRow'
import './index.scss'


const data=[
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },

]


export default function Index() {
    const navigate = useNavigate();
    return <>
        <NavBar onBack={() => {
            navigate(-1)
        }}> TOP 100 of Win</NavBar>
        <Body type={H5Body.type2}>
            <List className='topWinList'>
                {data.map((item,index)=>{
                    return  <ListItem item={item} index={index} key={index} />
                })} 
            </List>
        </Body>
    </>
}