
import imgLogo1 from '@assets/images/home/logo.png';
import imgLogo2 from '@assets/images/home/logo1.png';
import icon_chouti from '@assets/images/h5/icon_chouti.svg'
import { Dropdown, DropdownRef } from 'antd-mobile'
import { ConnectButton, useConnectModal, useAccount } from '@particle-network/connect-react-ui'
import './index.scss'
import { getAddress } from '@/utils/stringFormat';
import useWeb3 from '@hooks/useWeb3';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { config } from '@/config'; 
import vipLogo from '@assets/images/h5/vipLogo.svg'
import { useSelector } from 'react-redux';

export default function Index() {
    const connectKit: any = useConnectModal();

    const account = useAccount();
    const location = useLocation();
    // const { cacheconnect } = useParticleConnect();

    const navigate = useNavigate();
    const ref = useRef<DropdownRef>(null)
    useWeb3();

    const push = (url: string) => {
        navigate(url);
        ref.current?.close()
    }

    const { whiteList } = useSelector((state: any) => {
        return {
            whiteList: state.globalModule.whiteList
        }
    })

    function openRule() {
        const issuanceType = sessionStorage.getItem('issuanceType')
        if (issuanceType === '1of2') {
            push('/h5Rules')
        } else {
            push('/rules')
        }
    }


    return <div className="h5Header">
        <div className="logo" onClick={() => {navigate('/')}}>
            <img src={imgLogo1} className={"logo1"} alt="" />
            <div className="logox">x</div>
            <img src={imgLogo2} className={"logo2"} alt="" />
        </div>
        <div className="right">
            <ConnectButton.Custom>
                {(cusTomProps) => {
                    return <div className={`connectBtn ${cusTomProps.account && !config.chainIds.includes(cusTomProps.chain?.id || 0) && 'wrong'}`} onClick={() => {
                        if (cusTomProps.account && config.chainIds.includes(cusTomProps.chain?.id || 0)) {
                            if (location.pathname !== "/balance") {
                                navigate("/balance");
                            }
                        } else if (cusTomProps.account && !config.chainIds.includes(cusTomProps.chain?.id || 0)) {
                            cusTomProps.openChainModal()
                        } else {
                            cusTomProps.openConnectModal();
                        }
                    }}>
                        {cusTomProps.account ? <>
                            {config.chainIds.includes(cusTomProps.chain?.id || 0) ? getAddress(account || '') : "Wrong network"}
                        </> : 'connect wallet'}
                    </div>
                }}
            </ConnectButton.Custom>
            
            {
                location.pathname !== '/' && whiteList.length > 0 &&
                <img src={vipLogo} onClick={() => {navigate('/whiteList')}} />
            }

            {
                location.pathname !== '/' &&
                <Dropdown className='h5headerDropDown' ref={ref}>
                    <Dropdown.Item className='h5headerDropDownItem' key='sorter' arrow={false} title={<div>
                        <img src={icon_chouti} />
                    </div>}>
                        <div className='h5_header_panel'>
                            <div className='h5_header_panel_Item' onClick={() => {
                                push('/');
                            }}>
                                MINT
                            </div>
                            <div className='h5_header_panel_Item' onClick={() => {
                                push('/trade');
                            }}>
                                Trade
                            </div>
                            <div className='h5_header_panel_Item' onClick={() => {
                                window.open(config.gametaHomeUrl)
                            }}>
                                Gameta
                            </div>
                            
                            <div className='h5_header_panel_Item' onClick={openRule}>
                                Rules
                            </div>
                            <div className='h5_header_panel_Item' onClick={() => {
                                push('/mint');
                            }}>
                                My lucky mint
                            </div>
                            <div className='h5_header_panel_Item' onClick={() => {
                                navigate('/collection', {
                                    state: {
                                        page: 1
                                    }
                                });
                                ref.current?.close()
                            }}>
                                My Collection
                            </div>
                            <div className='h5_header_panel_Item' onClick={() => {
                                window.open(config.gitbookUrl)
                            }}>
                                Q&A
                            </div>
                        </div>
                    </Dropdown.Item>
                </Dropdown>
            }
        </div>
    </div>
}