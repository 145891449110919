import './whiteTips.scss'
import { Mask } from "antd-mobile";
import closeSvg from '@/assets/images/h5/close.svg'
import whiteImg from '@/assets/images/home/white-bottom.svg'

const free = [
  'No charge for MINT',
  'Scope of application: 1 of 5',
  'MINT option: 5 MINT',
  <div>MINT Total Price: 0 (<span>0.06</span>) BNB/MINT</div>
]

const breakeven = [
  'If the repurchase amount is less than the payment amount, the official will compensate for the loss',
  'Scope of application: 1 of 2',
  'MINT option: 1 MINT',
  <div>MINT Total Price: 0.04 (<span>0.06</span>) BNB/MINT</div>
]

const discount = [
  'Use discount price to participate in MINT',
  'Scope of application: 1 of 2',
  'MINT option: 1 MINT',
  <div>MINT Total Price: 0.03 (<span>0.06</span>) BNB/MINT</div>
]

export default function Index({ visible, onClose, title, remarkList  }: { visible: boolean, onClose?: Function, title:string | '', remarkList: any | [] }) {
  return <Mask visible={visible} onMaskClick={() => {
      onClose && onClose();
    }}>
      <div className="maskContainer whiteContainer">
        <img className="closeIcon" src={closeSvg} onClick={()=>{
          onClose && onClose(0);
        }}/>
        <div className='topReamrk'>You will use the following privileges in this MINT</div>
        <div className='card'>
          <div className='cardInner'>
            <div className='title'>{title}</div>
            <div className='remark'>
              {
                remarkList.map((item:any , index: any) => 
                  <div key={index} className='detail' dangerouslySetInnerHTML={{__html: item}} />
              )}
            </div>
          </div>
          <img className='bottom' src={whiteImg} alt="" />
          <div className='btn' onClick={()=>{
            onClose && onClose(1);
          }}>Certain</div>
        </div>
      </div>
  </Mask>
}