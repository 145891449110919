
import './index.scss'
import Footer from '@components/h5Footer'
// import Partner from '@components/h5Partner'
// import elementSvg from '@assets/images/h5/element.svg'
// import Content from '@/components/h5CardContent'
// import StartMint from '@/components/h5StartMint'
import Body, { H5Body } from '@components/h5Body'
import { Navigate, useLocation } from 'react-router-dom'
import H5ChristmasDeng from '@/components/h5ChristmasDeng'
import { useDispatch, useSelector } from 'react-redux'
import xh15 from '@assets/images/h5/xh15.png';
import xh16 from '@assets/images/h5/xh16.png';
import xh17 from '@assets/images/h5/xh17.png';
import mintBtn from '@assets/images/h5/mintBtn.png';
import history from '@assets/images/h5/history.svg';
import card1 from '@assets/images/h5/card1.png';
import card2 from '@assets/images/h5/card2.png';
import element_logo from '@assets/images/home/element_logo.png';
import tapd from '@assets/images/home/tapd.png';
import { RightOutlined, SyncOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { getInfo_func, getWhiteList_Func, issuance_Func, getHyperMint_Func } from '@/features/globalClice'
import React, { useState, useEffect } from "react";
import Popup from '@/components/h5Tips/popup';
import Congratulations from '../congratulations2'
import load_white_gif from '@/assets/images/home/load_white.gif'
import H5Loading from '@/components/h5Tips/h5Loading'
import { useAccount, useConnectModal } from "@particle-network/connect-react-ui";
import { create_order_func, cancel_order_func, ReasonEnum, payDone_func } from '@features/orderClice';
import H5Tips from '@/components/h5Tips'
import KfTips from '@/components/h5Tips/kefuTips'
import WhiteTips from '@/components/h5Tips/whiteTips'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import log2Img from '../../../assets/images/home/log2.png'
import discordImg from '../../../assets/images/home/icon-Discord.png';
import deng1 from '@assets/images/h5/deng1.svg';
import deng2 from '@assets/images/h5/deng2.svg';


export default function Index() {
    const [visible, setVisible] = useState(false);
    const [drawerStat, setDrawerStat] = useState(false);
    const [topMint, setTopMint] = useState([])
    const [orderId, setOrderId] = useState(0);
    const [whiteGif, setWhiteGif] = useState(false)
    const [loadGif, setLoadGif] = useState(false)
    const [test, setTest] = useState('')
    const [btnText, setBtnTest] = useState('')
    const [kfVisible, setkfVisible] = useState(false)
    const [whiteVisible, setWhiteVisible] = useState(false)
    const [roate, setRoate] = useState(false)
    const [title, setTitle] = useState('')
    const [remarkList, setRemarkList] = useState([])
    const [whiteData, setWhiteData] = useState({ order: { id: 0 } })
    const connectKit: any = useConnectModal();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const account: any = useAccount();

    const { issuanceObj, itemInfo, orderInfo, token, hyperMint }: any = useSelector((state: any) => {
        return {
            orderInfo: state.orderModule.orderInfo,
            issuanceObj: state.globalModule.issuance,
            itemInfo: state.globalModule.itemInfo,
            token: state.loginModule.token,
            hyperMint: state.globalModule.hyperMintList
        }
    })


    useEffect(() => {
        dispatch(issuance_Func())
        dispatch(getHyperMint_Func());
        if (account) {
            dispatch(getWhiteList_Func())
        }
    }, [itemInfo?.phase, token])

    useEffect(() => {
        setHyperMint()
    }, [hyperMint])

    function setHyperMint() {
        const arr: any = []
        const hyperMintList: any = [];
        const mintList = hyperMint
        mintList.forEach((item: any, index: any) => {
            if (index < 10) {
                hyperMintList.push(item)
            }
        })
        for (let k = 0; k < hyperMintList.length; k++) {
            const obj = {
                priceInGame: '',
                recyclingPrice: '',
                images1: '',
                images2: '',
                newBaby: ''
            }
            obj.priceInGame = hyperMintList[k].priceInGame
            obj.recyclingPrice = hyperMintList[k].recyclingPrice
            let image = hyperMintList[k].images
            const newImage = image.split(",")
            obj.images1 = newImage[0] ? newImage[0] : ''
            obj.images2 = newImage[1] ? newImage[1] : ''
            obj.newBaby = hyperMintList[k].wallet.substring(0, 2) + '...' + hyperMintList[k].wallet.substring(hyperMintList[k].wallet.length - 2, hyperMintList[k].wallet.length)
            arr.push(obj)
        }
        setTopMint(arr)
    }

    function onBtnClick() {
        navigate('/mint')
    }

    function refreshData() {
        setRoate(true)
        setTimeout(() => {
            setRoate(false)
        }, 1500)
        dispatch(issuance_Func())
    }

    function closeWhite(stat: any) {
        if (stat === 0 || stat === undefined) {
            dispatch(cancel_order_func(ReasonEnum.cancel, () => {
                setWhiteVisible(false);
            }))
        } else if (stat === 1) {
            setWhiteVisible(false)
            window.web3.eth.getBalance(account).then(value => {
                const balance = window.web3.utils.fromWei(value, "ether");
                if (Number(orderInfo.totalPrice) > Number(balance)) {
                    // dispatch(cancel_order_func(ReasonEnum.cancel, () => {
                    //   setShowBuyBackDialog(false);
                    // }))
                    setWhiteGif(false)
                    navigate('/addPurchase')
                } else {
                    setWhiteGif(true)
                    dispatch(payDone_func((status) => {
                        setWhiteGif(false)
                        if (status == 0) {
                            setLoadGif(true);
                        }
                        if (status == 1) {
                            setLoadGif(false);
                            dispatch(getWhiteList_Func())
                            setDrawerStat(true)
                            setOrderId(whiteData.order.id)
                        } else if (status == 2) {
                            //failed
                        } else if (status == 3) {
                            //payFailed
                            // setShowGameDialog(true)
                        } else if (status == 4) {
                            // paidFailed
                        } else if (status === 5) {
                            // 无效订单
                            setkfVisible(true)
                            setTest('This payment is abnormal,unable to mint.')
                            setBtnTest('')
                        }
                    }, () => {
                        setLoadGif(false);
                    }));
                }
            });
        }
    }

    function mint() {
        if (account) {
            dispatch(create_order_func('1', (data, status) => {
                if (status) {
                    if (data.order.usedWhitelist) {
                        if (data.order.actualPrice === '0') {
                            setTitle('Free')
                            const remark: any = [
                                'No charge for MINT',
                                `<span>Scope of application: ${itemInfo.type}</span>`,
                                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
                            ]
                            setRemarkList(remark)
                        } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === true) {
                            setTitle('Breakeven')
                            const remark: any = [
                                `If the repurchase amount is less than the payment amount, the official will compensate for the loss`,
                                `<span>Scope of application: ${itemInfo.type}</span>`,
                                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
                            ]
                            setRemarkList(remark)
                        } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === false) {
                            setTitle('Discount')
                            const remark: any = [
                                `Use discount price to participate in MINT`,
                                `<span>Scope of application: ${itemInfo.type}</span>`,
                                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
                            ]
                            setRemarkList(remark)
                        }
                        setWhiteVisible(true)
                        setWhiteData(data)
                    } else {
                        setWhiteGif(true)
                        window.web3.eth.getBalance(account).then(value => {
                            const balance = window.web3.utils.fromWei(value, "ether");
                            if (Number(data.order.totalPrice) > Number(balance)) {
                                setWhiteGif(false)
                                navigate('/addPurchase')
                            } else {
                                // 不是直接调用支付（此时用户为普通用户）
                                dispatch(payDone_func((status) => {
                                    setWhiteGif(false)
                                    if (status == 0) {
                                        setLoadGif(true);
                                    }
                                    if (status == 1) {
                                        setLoadGif(false);
                                        dispatch(getWhiteList_Func())
                                        setDrawerStat(true)
                                        setOrderId(data.order.id)
                                    } else if (status == 2) {
                                        //failed
                                        // setShowLuckDialog(true)
                                    } else if (status == 3) {
                                        //payFailed
                                        // setShowGameDialog(true)
                                    } else if (status == 4) {
                                        // paidFailed
                                    } else if (status === 5) {
                                        // 无效订单
                                        setkfVisible(true)
                                        setTest('This payment is abnormal,unable to mint.')
                                        setBtnTest('')
                                    }
                                }, () => {
                                    setLoadGif(false);
                                }));
                            }
                        });
                    }
                } else {
                    if (data === 'SI060') {
                        // 尚未发售订单
                        setVisible(true)
                        setTest('The time for lucky mint has not yet arrived,\n please wait patiently.')
                        setBtnTest('Confirm')
                    }
                    if (data === 'SI052') {
                        setVisible(true)
                        setTest('There is an order has not been completed,\n please complete the payment first.')
                        setBtnTest('view')
                    }
                    if (data === 'SI051') {
                        setVisible(true)
                        setTest('Insufficient inventory,\n please reduce the number of lucky mint.')
                        setBtnTest('Confirm')
                    }
                }
            }))
        } else {
            connectKit.openConnectModal()
        }

        // setLoadGif(true)
    }

    function goDiscord() {
        window.open('https://discord.com/invite/yqa229h7tZ')
    }

    function goTwitter() {
        window.open('https://twitter.com/GametaHippo')
    }

    function back() {
        navigate('/')
    }

    const state = useLocation().state;
    if (state == undefined) {
        return <Navigate replace to="/" />
    }
    return <>
        <Body className='christmasContainer'>

            <div className='christmasTitle'>

                <img src={xh16} className="xh16" />
                <img src={xh17} className="xh17" />
                {issuanceObj ? issuanceObj.title : ''}
            </div>
            <div className='christmasCenter'>
                <div className='topScoll'>
                    {
                        topMint && topMint.length !== 0 &&
                        <Swiper
                            className="h5Swiper-container1 swiper-no-swiping"
                            spaceBetween={0}
                            slidesPerView={1}
                            autoplay={{
                                delay: 1000, // 默认延迟3s播放下一张
                                stopOnLastSlide: false, // 播放到最后一张时停止：否
                                disableOnInteraction: false // 用户操作轮播图后停止 ：是
                            }}
                            direction='vertical'
                            loop
                        >
                            {
                                topMint.map((item: any, index: any) =>
                                    <SwiperSlide className="h5Swiper-slide" key={index}>
                                        <div className="baby">
                                            <div className="beele">{item.newBaby}</div>
                                        </div>
                                        <span className="successful">Won<span className="sol">{' ' + item.recyclingPrice + ' '}</span> BNB of Lucky Mint</span>
                                        <div className="prize">
                                            {
                                                item.images1 && <img src={item.images1} alt="" />
                                            }
                                            {/* {
                                        item.images1 && <div className="ellipsis">...</div>
                                    } */}
                                        </div>
                                    </SwiperSlide>
                                )}
                        </Swiper>
                    }


                </div>
                <img src={xh15} className="xh15" />
                <div className='center'>
                    <H5ChristmasDeng img1={deng1} img2={deng2} />
                    <div className="cards">
                        <div className='card card1'>
                            <img src={card1} className="cardImg" />
                            <div className="blance">
                                <label>0.1</label> BNB
                            </div>
                            <div className='text'>
                                <img src={element_logo} className="logo" /> Floor price
                            </div>
                        </div>
                        <div className='card card2'>
                            <img src={card2} className="cardImg" />
                            <div className="blance">
                                <label>20</label> USDT
                            </div>
                            <div className='text'>
                                <img src={tapd} className="logo" />  Gaming Value
                            </div>
                        </div>
                    </div>

                    <div className="btm">
                        <div>
                            {issuanceObj ? (issuanceObj.total - issuanceObj.sold) : '0'}/{issuanceObj ? issuanceObj.total : '0'} <SyncOutlined onClick={refreshData} className='refresh' spin={roate} />
                        </div>
                        <div onClick={() => {
                            navigate('/h5Rules')
                        }}>
                            Rules<RightOutlined />
                        </div>
                    </div>
                </div>
                <div className='btns'>
                    <img src={mintBtn} className="mintBtn" onClick={mint} />
                    <div className='mintText'>
                        <label>{issuanceObj ? issuanceObj.price : '0.3'}</label> {issuanceObj ? issuanceObj.currency : 'BNB'} / mint
                    </div>

                    <img onClick={() => { navigate('/mint') }} src={history} className="historyBtn" />
                </div>
            </div>
        </Body>
        {
            whiteGif &&
            <div className='white'>
                <img src={load_white_gif} className="loadWhite" alt="" />
                <div className='propgr'>Payment is in progress. <br />Please do not close or refresh the webpage.</div>
            </div>
        }
        {
            loadGif &&
            <div className='white2'>
                <H5Loading />
            </div>
        }
        {
            issuanceObj?.status === 4 &&
            <div className='mask'>
                <div className='maskContent'>
                    <div className='talk'>This issue of Lucky Mint has ended. <br />If you want to continue participating, <br />please join the community.</div>
                    <div className='btn'>
                        <div className='one'><img className="img4" onClick={goDiscord} src={discordImg} alt="" /></div>
                        <div><img className="img3" onClick={goTwitter} src={log2Img} alt="" /></div>
                    </div>
                    <div className='back' onClick={back}>Back</div>
                </div>
            </div>
        }
        <Footer />
        <Popup visible={drawerStat}>
            <Congratulations orderId={orderId} onContinue={() => { setDrawerStat(false); mint() }} onClose={() => {
                setDrawerStat(false)
            }} />
        </Popup>
        <H5Tips visible={visible} onClose={() => { setVisible(false) }} text={test} btnText={btnText} onBtnClick={onBtnClick} />
        <KfTips visible={kfVisible} onClose={() => { setkfVisible(false) }} text={test} type={1} />
        <WhiteTips visible={whiteVisible} onClose={(stat: any) => { closeWhite(stat) }} title={title} remarkList={remarkList} />
    </>
}