
import { useEffect, useState } from 'react';
import SignatureCol from './signatureCol'
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getUserOrders_Func, setUserOrders } from '@/features/globalClice';
import { useAccount } from '@particle-network/connect-react-ui';
import CountDown from '../countDown';
import { useNavigate } from 'react-router-dom'

export default function Index() {
    const navigator = useNavigate()
    const [hasScroll, setHasScroll] = useState(false);

    const dispatch = useDispatch();
    const account = useAccount();

    const userOrdersList = useSelector((state: RootState) => {
        return state.globalModule.userOrdersList
    })
    useEffect(() => {
        if (account) {
            dispatch(setUserOrders([]))
            if (account) {
                dispatch(getUserOrders_Func());
            }
        } else {
            dispatch(setUserOrders([]))
        }
    }, [account])


    const getStatusText = (status: Number) => {
        if (status === 0) {
            return "Paying"
        } else if (status === 1) {
            return "Payment completed"
        } else if (status === 2) {
            return "Minting"
        } else if (status === 3) {
            return "Success"
        } else if (status === 4) {
            return "Payment Cancelled"
        } else if (status === 5) {
            return "Refunding"
        } else if (status === 6) {
            return "Refunded"
        } else if (status === 1) {
            return ""
        }
    }

    function openResult(id: string) {
        navigator("/congratulations", {
            state: {
                orderId: id
            }
        })
    }

    return <div className="mintBody">

        <div className={`fixed ${hasScroll ? 'soll' : ''}`}>
            <div className="tableHead">
                <div className="col col1"> &nbsp;</div>
            </div>
            <div className="tableBody">
                {userOrdersList && userOrdersList.map((item: any) => {
                    if (item.status !== 0 && item.status !== 4) {
                        return <div key={item.id} className="col col1" onClick={() => openResult(item.id)}>View</div>
                    }
                    if (item.status === 0) {
                        return <div key={item.id} className="col col1"><CountDown createTime={item.createTime} /></div>
                    }
                    return <div key={item.id} className="col col1">&nbsp;&nbsp;</div>

                })}
            </div>
        </div>
        <div className="h5MintTable" onScroll={(e) => {
            if (e.currentTarget.scrollLeft === 0 && hasScroll) {
                console.log(1)
                setHasScroll(false)
            } else if (e.currentTarget.scrollLeft !== 0 && !hasScroll) {
                console.log(2)
                setHasScroll(true)
            }
        }}>
            <div className="tableHead">
                <div className="col col2">MINT</div>
                <div className="col col3">Cost</div>
                <div className="col col4">Status</div>
                <div className="col col5">Time</div>
            </div>
            <div className="tableBody">
                {userOrdersList && userOrdersList.map((item: any) => {
                    return <div key={item.id}>
                        <div className="col col2">{item.quantity} Mint</div>
                        <div className="col col3">{item.actualPrice} BNB</div>
                        <div className="col col4">{getStatusText(item.status)}</div>
                        <div className="col col5">{item.time}</div>
                    </div>
                })}

                {
                    (userOrdersList && userOrdersList.length == 0) ? <div className='table-tips'> No data </div> : <div className='table-tips'> No more </div>
                }
            </div>
        </div>
    </div>
}