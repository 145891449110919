import './index.scss'; 
import toubiao from '@assets/images/h5/toubiao.svg'
export default function Index({ type, data, price }: { type?: 'HM' | 'GMB', data: any, price:any }) {
    return <div className={`h5NTFItemContainer ${type == 'GMB' && 'h5NTFMHItemContainer'}`}>
        <div className='ntmImg' style={type == 'HM' ? { backgroundImage: `url(${data.bgImage})` } : undefined}>
            {type == 'GMB' && <img src={toubiao} alt="" className='toubiao' />}
            <img src={data.image} alt="" />

        </div>
        <div className='title'>
            {data.name}
        </div>
        {
            data.manghe === 1 ? <div className='price'>
                Gaming Value:<br />
                {price + ' '}USDT
            </div> : <div className='price'>
                Floor Price:<br /> 
                {price + ' '}BNB
            </div>
        }
    </div>
}