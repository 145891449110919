
import { useNavigate } from 'react-router-dom'
import Body, { H5Body } from '@components/h5Body'
import NavBar from '@components/H5NavBar' 
import './index.scss'


export default function Index() {
    const navigate = useNavigate();
    return <>
        <NavBar onBack={() => {
            navigate(-1)
        }}> Rule </NavBar>
        <Body type={H5Body.type2} className="rulesContainer">
            <div className="rulesContai">
                <div className="one">
                    <div>Name</div>
                    <div>TYPE</div>
                    <div>Gameta value</div>
                    <div>NFT Market Price</div>
                    <div>Mint Probability</div>
                    <div>Buyback Guaranteed by</div>
                </div>
                <div className="two">
                    {/* <div>Uncommon Hippo<br/>Gameta Hippo Christmas NFT</div>
                    <div>PFP NFT<br />In-game Items</div>
                    <div>28 USDT</div>
                    <div>Floor Price*<br />0.01 BNB</div>
                    <div>50%</div>
                    <div>Community Network</div> */}
                    <div>Dash Hippo Blue</div>
                    <div>In-game Items</div>
                    <div>10 USDT</div>
                    <div>Play To Earn</div>
                    <div>50%</div>
                    <div>/</div>
                </div>
                <div className="last">
                    {/* <div>GCMB<br/>Gameta Christmas Mystery Box</div>
                    <div>In-game Items</div>
                    <div>20 USDT</div>
                    <div>Play To Earn</div>
                    <div>50%</div>
                    <div>/</div> */}
                    <div>Dash Hippo Red</div>
                    <div>In-game Items</div>
                    <div>10 USDT</div>
                    <div>Play To Earn</div>
                    <div>50%</div>
                    <div>/</div>
                </div>
            </div>
        </Body>
    </>
}