import './index.scss';
import histroyTopicon from '@assets/images/h5/histroyTopicon.svg';
import histroyLefticon from '@assets/images/h5/histroyLefticon.svg';
import histroyRighticon from '@assets/images/h5/histroyRighticon.svg';
import historyCenterIcon from '@assets/images/h5/historyCenterIcon.svg';
import { useNavigate } from 'react-router-dom'

export default function Index() {
    const navigate = useNavigate();
    return <div className="historyPanel">
        <img src={histroyTopicon} className="top top1" />
        <img src={histroyTopicon} className="top top2" />
        <img src={histroyLefticon} className="histroyLefticon" />
        <img src={histroyRighticon} className="histroyRighticon" />
        <img src={historyCenterIcon} onClick={() => { navigate('/mint') }} className="historyCenterIcon" />
    </div>
}