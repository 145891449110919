import { List } from "antd-mobile";
import './index.scss';
import jiangbei1Svg from '@assets/images/h5/jiangbei1.svg';
import jiangbei2Svg from '@assets/images/h5/jiangbei2.svg';
import jiangbei3Svg from '@assets/images/h5/jiangbei3.svg';
import solanaVerticalLogo from '@assets/images/h5/solanaVerticalLogo.svg';
import Bnb from '@assets/images/h5/bnb.png';
import Bnb2 from '@assets/images/h5/bnb2.png'

export default function Index({ item, index }: { item: any, index: number }) {
    return <List.Item className="top_win_itemItem">
        <div className="top_win_itemRow">
            <div className="col1">
                {index === 0 && <img src={jiangbei1Svg} alt="" />}
                {index === 1 && <img src={jiangbei2Svg} alt="" />}
                {index === 2 && <img src={jiangbei3Svg} alt="" />}
                {index > 2 && index < 10 && `0${index}`}
                {index >= 10 && index}
            </div>
            <div className="col2">
                <div className="top">
                    <img src={solanaVerticalLogo} />  4.1
                </div>
                <div className="bottom">$146.53</div>
            </div>
            <div className="col3">
                <img src={Bnb} alt=""/>
                <img src={Bnb2} alt=""/>
                <img src={Bnb2} alt=""/>
                <label>...</label>
            </div>
            <div className="col4">
                BEEBLE BABY
            </div>
        </div>
    </List.Item>
}