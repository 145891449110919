
import Body, { H5Body } from '@components/h5Body';
import H5NTFItem from '@components/h5NTFItem';
import Footer from '@components/h5CollectionFooter';
import FenxiangSvg from '@assets/images/h5/fenxiang.svg'
import right from '@assets/images/h5/right.svg'
import { useNavigate } from 'react-router-dom'
import './index.scss'
import { useEffect, useState } from 'react';
import { get_NFTs_func, setNFTs } from '@/features/orderClice';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from '@particle-network/connect-react-ui';
import { RootState } from '@/store';
import { LoadingOutlined } from '@ant-design/icons';
import data_loading from '@assets/images/home/Date-loading.png';

export default function Index() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const account = useAccount()

    const [loading, setLoading] = useState(false)



    const { chainId, hmNftList, HMTotalPrice, MHTotalPrice } = useSelector((state: RootState) => {
        const chain: any = state.globalModule.chain
        return {
            chainId: chain?.id,
            hmNftList: state.orderModule.NFTs,
            HMTotalPrice: state.orderModule.HMTotalPrice,
            MHTotalPrice: state.orderModule.MHTotalPrice
        }
    })
 
    useEffect(() => {
        init();
    }, [account,chainId])

    function init() {
        if (account && chainId) {
            setLoading(true);
            dispatch(get_NFTs_func(chainId, [account], () => {
                setLoading(false);
            }))
        } else {
            dispatch(setNFTs([]))
        }
    }

    // 刷新收集页面数据
    function refreshData() {
        init()
    }
    return <>
        <Body className="collectioneContainer">
            <div>
                <div className='topCard'>
                    <div className='topPanel'>
                        <img src={right} onClick={() => { navigate(-1) }} alt="" />
                        <img src={FenxiangSvg} alt="" />
                    </div>
                </div>
                {
                    (hmNftList && hmNftList.length > 0) && <div className="titleCard">
                        <div className='title'> MY COLLECTION</div>
                        <div className='text'>Gaming Value: {MHTotalPrice} USDT, Floor Price: {HMTotalPrice} BNB</div>
                    </div>
                }


                {loading ? <div className='loadIconPanel'>
                    <LoadingOutlined className='loadIcon' />
                </div> : <div className={`ntfsContainer ${!(hmNftList && hmNftList.length > 0) && 'noNtfsContainer'}`}>

                    {
                        (hmNftList && hmNftList.length > 0) ? hmNftList.map((item: any) => {
                            return <H5NTFItem key={item.name} price={item.manghe===1? item.game_value: item.recyclingPrice} data={item} type={item.manghe === 1 ? 'GMB' : 'HM'} />
                        }) : <div className='noData'>
                            <img src={data_loading} />
                            <label>Data loading failed, please try again.</label>
                            <div className='noDataBtns' onClick={refreshData}>
                                Retry
                            </div>
                        </div>
                    }

                </div>}


            </div>

        </Body>
        {
            (hmNftList && hmNftList.length > 0) && <Footer disabled={!(hmNftList && hmNftList.length > 0)} />
        }

    </>
}