import Support from '@/components/pc/support'
import { getHyperMint_Func, getPrice_Func, getUrls_Func, isRestrictedCountry_Func, issuance_Func } from '@/features/globalClice'
import useRefreshToken from '@/hooks/useRefreshToken'
import { RootState } from '@/store'
import Header from '@components/h5Header'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import './index.scss'

export default function Index() {

    
    useRefreshToken()
    // 调用接口设置当前是否为禁用地区展示对应页面
    const [country, setCountry] = useState(false);
    const [pageStat, setPageStat] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(isRestrictedCountry_Func((bo: boolean) => {
            if (bo) {
                setCountry(false);
                setPageStat(true)
            } else {
                setCountry(true)
                dispatch(issuance_Func())
                dispatch(getUrls_Func())
                dispatch(getPrice_Func())
            }
        }))
        dispatch(getHyperMint_Func());
    }, [])
    // const isMobile: any = localStorage.getItem("isMobile")


    // if (isMobile == 0) {
    //     return <Navigate to="/" replace={true} />
    // }
    return country ? <div className="h5container">
        <Header />
        <Outlet />
    </div> : <div className="isTrue" style={{ padding: '20px' }}>
        {pageStat && <Support />}
    </div>
}