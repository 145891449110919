
import './index.scss'
import Footer from '@components/h5Footer'
import Partner from '@components/h5Partner'
import elementSvg from '@assets/images/h5/element.svg'
import Content from '@/components/h5CardContent'
import StartMint from '@/components/h5StartMint'
import Body, { H5Body } from '@components/h5Body'
import { useLocation } from 'react-router-dom'
import { Popup } from 'antd-mobile'
import RulesAndGame from '@/components/h5RulesAndGame'
import { useSelector } from 'react-redux'
import HippoList from '@components/h5HippoList/index'
import goTop from '@assets/images/h5/toTop.svg';
import leftImg from '@/assets/images/home/left.png'
import rightImg from '@/assets/images/home/right.png'

import './index.scss'

export default function Index() {
    // const location = useLocation();
    // const {issuanceObj}:any = useSelector((state:any)=>{
    //     return {
    //         issuanceObj: state.globalModule.issuance,
    //     }
    // })
    return <>
        <Body className='hippoListContainer '>
            <div className="title">
                <img className="partnerLeft" src={leftImg} alt="" />
                <div className="road">LUCKY MINT</div>
                <img className="partnerRight" src={rightImg} alt="" />
            </div>
         
            <HippoList />
            <Partner />
            <div className='goTopBtn' onClick={() => {
                const doce = document.getElementById("h5Body")
                if (doce) {
                    doce.scrollTop = 0
                }
            }}>
                <img src={goTop} />
            </div>
        </Body>
        <Footer />
    </>
}