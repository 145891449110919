import './index.scss'
import { useNavigate } from 'react-router-dom'
import Body, { H5Body } from '@components/h5Body' 
import NavBar from '@components/H5NavBar'
import boxImg from '@/assets/images/home/not-open.png'

function Trade() {
  const navigate = useNavigate()
  return (
    <div className='trade'>
      <NavBar onBack={() => {
        navigate(-1)
      }}> Trade </NavBar>
      <Body type={H5Body.type2} className="tradeContainer">
        <div className='content'>
          <img src={boxImg} className="box" alt="" />
          <div className='remark'>The marketplace is under construction,<br /> follow our twitter (<span onClick={() => { window.open('https://twitter.com/GametaHippo') }}>https://twitter.com/<br />GametaHippo</span>)to receive first-hand<br /> progress updates.</div>
        </div>
      </Body>
    </div>
  )
}

export default Trade