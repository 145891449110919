import ttImg from '@assets/images/h5/icon_tt.svg'
import disccord from '@assets/images/h5/icon_disccord.svg'
import './index.scss'
import { config } from '@/config'

export default function Index() {
    return <div className="h5footer">
        <div className='text'>
            <label>© 2022 SOGA. </label>
            <label>Terms | Privacy</label>
        </div>
        <div className="icon">
            <img src={ttImg} alt="" onClick={() => {
                window.open(config.twitterUrl)
            }} />
            <img src={disccord} alt="" onClick={() => {
                window.open(config.discordUrl)
            }} />
        </div>
    </div>
}