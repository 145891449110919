
import Body, { H5Body } from '@components/h5Body';
import H5NTFItem from '@components/h5NTFItem';
import Footer from '@components/h5CongratulationsFooter';
import FenxiangSvg from '@assets/images/h5/fenxiang.svg'
import right from '@assets/images/h5/right.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import './index.scss'
import { useEffect, useState } from 'react';
import { get_NFTs_func, get_result_func } from '@/features/orderClice';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from '@particle-network/connect-react-ui';
import { RootState } from '@/store';
import { LoadingOutlined } from '@ant-design/icons';

export default function Index() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();

    const { orderId } = location.state
    const account = useAccount()

    const [gmbList, setGmbList] = useState<any[]>([])
    const [floorValue, setFloorValue] = useState(0)
    const [gametaValue, setGametaValue] = useState(0)
    const [loading, setLoading] = useState(false)

    const metadata = useSelector((state: RootState) => {
        return state.globalModule.urls
    })

    const { chainId, orderInfo } = useSelector((state: RootState) => {
        const chain: any = state.globalModule.chain;
        const order: any = state.orderModule.orderInfo;
        return {
            chainId: chain?.id,
            orderInfo: order
        }
    })
    useEffect(() => {
        console.log(orderId, chainId)
        if (orderId && chainId) {
            init();
        }
    }, [orderInfo?.order.id, chainId])

    function init() {
        console.log(orderId, "========orderIdorderId")
        setLoading(true);
        dispatch(get_result_func(chainId, orderId, metadata, (data, type, gametaValue, floorValue) => {
            console.log(data, "========datadatadatadata")
            setGmbList(type ? [{...data,name:data.newName}] : data)
            setGametaValue(gametaValue)
            setFloorValue(floorValue)
            setLoading(false);
        }))
    }
 
    return <>
        <Body className="congratulationsContainer">
            <div>
                <div className='topCard'>
                    <div className='topPanel'>
                        <img src={right} onClick={() => { navigate(-1) }} alt="" />
                        <img src={FenxiangSvg} alt="" />
                    </div>
                </div>
                <div className="titleCard">
                    <div className='title'> congratulations</div>
                    {
                        (gmbList && gmbList.length == 1 && gmbList[0]?.manghe == 1) && <div className='text'>Won  {gmbList[0].priceInGame + ' '} USDT（Gaming Value）</div>
                    }
                    {
                        (gmbList && gmbList.length == 1 && gmbList[0]?.manghe == 0) && <div className='text'>Won   {gmbList[0].recyclingPrice + ' '} BNB （Gaming Value）</div>
                    }
                    {
                        (gmbList && gmbList.length > 1) && <div className='text'>Won {' ' + floorValue.toFixed(5) + ' '} BNB + {' ' + gametaValue.toFixed(2) + ' '} USDT（Gaming Value）</div>
                    }
                </div>
                <div className='subText'>
                    If the network is delayed, <br />
                    the NFTs will arrive at the wallet in 5-15 minutes.
                </div>
                <div className={`ntfsContainer ${!(gmbList && gmbList.length > 0) ? `noData` : ''}`}>
                    {
                        (gmbList && gmbList.length > 0) ? gmbList.map((item: any) => {
                            return <H5NTFItem  price={item.manghe === 1 ? item.priceInGame:item.recyclingPrice} key={item.name} data={item} type={item.manghe === 1 ? 'GMB' : 'HM'} />
                        }) :
                            <div className='loadIconPanel'>
                                <LoadingOutlined className='loadIcon' />
                            </div>
                    }

                </div>

            </div>

        </Body>
        <Footer disabled={false} gmbList={gmbList.length === 1? gmbList : []} />
    </>
}