import './index.scss'
import luckyMint from '@assets/images/h5/hippo-bg.png'
import end from '@assets/images/h5/end.svg';
import mintLogo from '@assets/images/h5/mintLogo.png';
import mint1Img from '@assets/images/home/1of2.png';
import mint5Img from '@assets/images/home/1of5.png';
import { useNavigate } from 'react-router-dom';
import { issuance_Func, setItemInfo } from '@features/globalClice'
import { useDispatch, useSelector } from 'react-redux'
import { hippoToPageMap } from '@/config';
import { RootState } from '@/store';

export default function Index(props: any) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { urls }: { urls: any } = useSelector((state: RootState) => {
        return {
          urls: state.globalModule.urls
        }
      })
    
      const getLogo = (item: any) => {
        if (urls) {
          console.log(urls,"======urlsurls")
          const logoList = item.images.split(',');
          const logo = logoList[logoList.length - 1];
          if (logo.includes('nfts')) {
            return `${urls}/${logo}`
          } else {
            return `${urls}/${logo}/preview.png`
          }
        } else {
          return mintLogo
        }
    
      }
    
    function enter() {
        if (props.item.status === 2 || props.item.status === 4) {
            dispatch(setItemInfo(props.item))
            sessionStorage.setItem('issuanceId', props.item.id)
            sessionStorage.setItem('issuanceType', props.item.type)

            dispatch(issuance_Func())
 
            const obj = hippoToPageMap.find(e => {
                return e.phase == props.item.phase
            })
            if (obj) {
                navigate(obj.pageUrl, { 
                    state: {
                        issuanceId: props.item.id
                    }
                })
            } else {
                if (props.item.type === '1of2') {
                    navigate('/hippomintmini', {
                        state: {
                            issuanceId: props.item.id
                        }
                    })
                } else {
                    navigate('/hippomint', {
                        state: {
                            issuanceId: props.item.id
                        }
                    })
                }
            }
        }
    }
    return <div className="h5HippoListLayoutItem" onClick={enter}>
        <div className='top'>
            {/* <div className='name'>
                {props.item.type}
            </div> */}
            {
                props.item.type === '1of2' ?
                    <img src={mint1Img} className="bg-img" /> :
                    <img src={mint5Img} className="bg-img" />
            }
            {
                props.item.status === 4 &&
                <img src={end} className="end" />
            }
            <img src={getLogo(props.item)} className="logo" />
        </div>
        <div className='btm'>
            <div className='text'>
                {props.item.title}
            </div>
            <div className='subtext'>
                {Number(props.item.total) - props.item.sold} / {props.item.total}
            </div>
            <div className='last'>
                {props.item.price} {props.item.currency}/MINT
            </div>
        </div>
    </div>
}