import Item from './item'
import './index.scss'
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { getListOnSale_func } from '@features/globalClice'

export default function Index() {
    const dispatch = useDispatch();
    const [editionList, setEditionList] = useState<Array<any>>([]);
    const [stat, setStat] = useState(false)
    const [lastArr, setLastArr] = useState<Array<any>>([])
    useEffect(() => {
        setStat(false)
        dispatch(getListOnSale_func((data: any) => {
            setEditionList(data || [])
            if (data.length > 6) {
                allSlice(data)
            } else {
                setLastArr(data || [])
            }
        }))
    }, [])

    function allSlice(data: any) {
        const arr = data.slice(0, 6)
        setLastArr(arr)
    }

    function showAll() {
        setLastArr(editionList)
        setStat(true)
    }

    function hidden() {
        const arr = editionList.slice(0, 6)
        setLastArr(arr)
        setStat(false)
    }

    return <div>
        <div className='h5HippoListLayout'>
            {
                lastArr.map((item: any, index:any) => 
                    <div key={index}>
                        <Item item={item} />
                    </div>
            )}
        </div>
        {
            editionList.length > 6 && stat === false &&
            <div className="viewBtns" onClick={showAll}>
                <div>View All</div>
            </div>
        }
        {
            stat &&
            <div className="viewBtns" onClick={hidden}>
                <div>Collapse</div>
            </div>
        }
    </div>
}