
import { useNavigate } from 'react-router-dom'
import Body, { H5Body } from '@components/h5Body'
import NavBar from '@components/H5NavBar';
import vector from '@assets/images/h5/vector.svg';
import { Toast } from 'antd-mobile';
import Mask from '@components/h5Tips/addFunsTips';

import './index.scss'
import { useState, useEffect } from 'react';
import { useAccount, useParticleProvider } from "@particle-network/connect-react-ui";
import { stringCopy } from '@/utils/stringFormat';
import { isEVMProvider } from "@particle-network/connect";
import {useSelector} from 'react-redux'


export default function Index() {
    const navigate = useNavigate();
    const account = useAccount();
    const provider = useParticleProvider();
    const [visible, setVisible] = useState(false);
    const [balance, setBalance] = useState<any>(0);

    const { chainId,actualPrice } = useSelector((state:any) => {
        return {
            chainId: state.globalModule.chain,
            actualPrice:state.orderModule.orderInfo?.order.actualPrice
        }
    })

    useEffect(() => {
        if (account && provider && (isEVMProvider(provider))) {
          window.web3.eth.getBalance(account).then((value:string) => {
            setBalance(window.web3.utils.fromWei(value, "ether"))
          })
        }
    }, [account,chainId])

    return <>
        <NavBar onBack={() => {
            navigate(-1)
        }}> Add funds to purchase </NavBar>
        <Body type={H5Body.type2} className="add_purchaseContainer">
            <div className='title'>
                You needd {actualPrice} BNB + <label>gas fees</label>
            </div>
            <div className='describe' >
                Transfer funds to your wallet or add funds with a card.
                It can take up to a minute for your balance to updadte.
            </div>

            <div className='balance'>
                <label>Wallet address:</label>
                <label>Balance:{balance} BNB</label>
            </div>
            <div className="wallet">
                <label>{account}</label>
                <img src={vector} onClick={() => {
                    stringCopy(account || '', (e: boolean) => {
                        if (e) {
                            Toast.show("Copied")
                        } else {
                            Toast.show("copy failed")
                        }
                    })
                }} alt="" />
            </div>

            <div className='continuePanel'>
                <div className="btn">
                    Continue
                </div>
            </div>

            <div className="add_funds" onClick={() => {
                setVisible(true)
            }}>
                Add funds
            </div>
        </Body>

        <Mask visible={visible} onClose={() => {
            setVisible(false)
        }} address={account || ''} />
    </>
}