
import { useNavigate } from 'react-router-dom'
import Body, { H5Body } from '@components/h5Body'
import NavBar from '@components/H5NavBar';
import ghost from '@assets/images/h5/ghost.png';
import frogprince from '@assets/images/h5/frogprince.png';
import InvestMaster from '@assets/images/h5/InvestMaster.png';
import antsrunner from '@assets/images/h5/antsrunner.png';
import HumanTower from '@assets/images/h5/HumanTower.png';
import sleepragdoll from '@assets/images/h5/sleepragdoll.png';
import NumberCount from '@assets/images/h5/NumberCount.png';
import TadpoleCount from '@assets/images/h5/TadpoleCount.png';
import superGlover from '@assets/images/h5/superGlover.png';
import CloneRush from '@assets/images/h5/CloneRush.png';

import kefu1 from '@assets/images/h5/kefu1.svg';
import kefu3 from '@assets/images/h5/kefu3.svg';
import kefu4 from '@assets/images/h5/kefu4.svg';
import {config} from '../../../config'
import './index.scss'

export default function Index() {
    const navigate = useNavigate();
    return <>
        <NavBar onBack={() => {
            navigate(-1)
        }}> GAME TO EARN</NavBar>
        <Body type={H5Body.type2} className="gameToEarnContainer">
            <div className='text'>
                As the world's #1 Web3 gaming platform (by user count), Gameta provides infrastructure and distribution services for ultra-casual games, attracting mass amount of mainstream players to easily transition to Web3. We achieve this through providing a matrix of ultra-casual games, and building a meta-universe that truly belongs to the users.
                <br/>
                <br/>
                Note:
                <br/>
                1) The value of all Gameta NFT means the revenue you can expect to earn by playing Gameta games (and complete game related tasks) in a fixed period of time;
                2) Users need to consistently complete games and tasks according to the official rules in order to maximize earnings;
                3) The final earnings that can be earned depend on the users’ participation frequency, participation period, game skill level, and can be strongly influenced by market fluctuations.
                4) Games supporting GMB and GHC are expected to be officially launched in early 2023.
                <br/>
                <br/>
                You can click below to try out the current Gameta games. Please note that these games <label>do not support</label> the use of GMB and GHC NFT at this time.
            </div>

            <div className='imgs'>
                <img src={ghost} onClick={()=>{
                    window.open('https://play.google.com/store/apps/details?id=com.fungames.ghostrun')
                }} alt="" />
                <img src={frogprince} onClick={() => {
                    window.open('https://play.google.com/store/apps/details?id=com.fungames.frogprince')
                }} alt="" />
                <img src={InvestMaster} onClick={()=> {
                    window.open('https://play.google.com/store/apps/details?id=com.fungames.investmaster')
                }} alt="" />
                <img src={antsrunner} onClick={()=>{
                    window.open('https://play.google.com/store/apps/details?id=com.walkman.antsrunner')
                }} alt="" />
                <img src={HumanTower} onClick={()=> {
                    window.open('https://play.google.com/store/apps/details?id=com.fungames.humantower')
                }} alt="" />
                <img src={sleepragdoll} onClick={()=>{
                    window.open('https://play.google.com/store/apps/details?id=com.fungames.sleepragdoll')
                }} alt="" />
                <img src={NumberCount} onClick={()=>{
                    window.open('https://play.google.com/store/apps/details?id=com.fungames.Numbercount')
                }} alt="" />
                <img src={TadpoleCount} onClick={()=>{
                    window.open('https://play.google.com/store/apps/details?id=com.fungames.tadpolecount')
                }} alt="" />
                <img src={superGlover} onClick={()=>{
                    window.open('https://play.google.com/store/apps/details?id=com.fungames.supergloves')
                }} alt="" />
                <img src={CloneRush} onClick={()=>{
                    window.open('https://play.google.com/store/apps/details?id=com.gameta.clonerush')
                }} alt="" />
            </div>

            <div className="kefus">
                <img src={kefu1} onClick={()=>{
                    window.open(config.gametaUrl)
                }} alt=""/>
                <img src={kefu3} onClick={()=>{
                    window.open(config.twitterUrl)
                }} alt=""/>
                <img src={kefu4} onClick={()=>{
                    window.open(config.discordUrl)
                }} alt=""/>
            </div>
        </Body>
    </>
}