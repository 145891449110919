import './index.scss'
import Body, { H5Body } from '@components/h5Body';
import right from '@assets/images/h5/right.svg'
import FenxiangSvg from '@assets/images/h5/fenxiang.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

function WhiteList() {
  const navigate = useNavigate();

  const { whiteList } = useSelector(state => {
    return {
      whiteList: state.globalModule.whiteList
    }
  })
  
  console.log(whiteList);

  return(
    <Body className='whiteList'>
      <div>
        <div className='topCard'>
          <div className='topPanel'>
              <img src={right} onClick={() => { navigate(-1) }} alt="" />
              <img src={FenxiangSvg} alt="" />
          </div>
        </div>
        <div className="titleCard">
          <div className='title'> whitelist</div>
          <div className='talk'>Dear whitelist customers,<br />you currently enjoy the following privileges:</div>
        </div>
        <div className='list'>
          {
            whiteList?.map((item, index) => 
              <div key={index} className='inner'>
                <div className='title'>{item.title}</div>
                <div className='remarkList'>
                  {
                    item.remarklist.map((ele, ind) => 
                      <div key={ind} className='remark' dangerouslySetInnerHTML={{__html: ele}} />
                  )}
                </div>
              </div>  
          )}
        </div>
      </div>
    </Body>
  )
}

export default WhiteList